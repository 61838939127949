import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/MinervaTools.net/MinervaTools.net/src/layout/simpleMdx.js";
import { Feather, Globe2, Loader, Activity, Expand, Lock, Code2 } from "lucide-react";
import FeatureListing from "../../src/components/FeatureListing/FeatureListing.js";
import * as styles from "./features.module.scss";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Features`}</h1>
    <Seo title={"Features"} description="Discover all the features offered by MinervaTools!" seoTitle={"Features of MinervaTools"} mdxType="Seo" />
    <div className={styles.featuresList}>
    <FeatureListing title={"Intuitive Web Interface"} icon={<Globe2 mdxType="Globe2" />} mdxType="FeatureListing">
        MinervaSpigot comes with MinervaPanel, an intuitive web interface
        allowing your builders to up- and download worlds, without ever needing
        FTP-access.
    </FeatureListing>
    <FeatureListing title={"Light on Memory"} icon={<Feather mdxType="Feather" />} mdxType="FeatureListing">
        MinervaSpigot automatically unloads worlds when they're not needed,
        saving on memory you can use for better things than containing worlds
        noone uses.
    </FeatureListing>
    <FeatureListing title={"Modern"} icon={<Loader mdxType="Loader" />} mdxType="FeatureListing">
        Development on MinervaTools started in 2021 from scratch. During
        development the needs of builders were put first in close colaboration
        with experienced Minecraft Marketplace Partners.
    </FeatureListing>
    <FeatureListing title={"Actively Developed"} icon={<Activity mdxType="Activity" />} mdxType="FeatureListing">
        Many plugins are dead in the water. MinervaTools however is being
        actively developed in close colaboration with builder teams to bring new
        features which will actually be used.
    </FeatureListing>
    <FeatureListing title={"Scaleable"} icon={<Expand mdxType="Expand" />} mdxType="FeatureListing">
        MinervaTools is being build in collaboration with both big and small
        builder teams. From small servers with just a few worlds the massive
        build server of one of the Top 5 Minercraft Marketplace sellers, we've
        tested our plugin on all sizes of server.
    </FeatureListing>
    <FeatureListing title={"Secure"} icon={<Lock mdxType="Lock" />} mdxType="FeatureListing">
        The web panel helps you cut down on how many people have FTP access to
        the server as it becomes trivial to up- and download worlds directly
        from a web browser. With the help of NGINX it can even be exposed over
        HTTPS.
    </FeatureListing>
    <FeatureListing title={"Extensible"} icon={<Code2 mdxType="Code2" />} mdxType="FeatureListing">
        MinervaSpigot comes with a local API ready to be used with your in-house
        tools and scripts.{" "}
        <a href="https://docs.minervatools.net/spigot/api">
            Look at the documentation
        </a>{" "}
        for more infos on the API.
    </FeatureListing>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      